import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ErrorModal from "../../../components/ModalTemplates/EmptyModal";
import SidePanel from "../../../components/SidePanel";
import SuccessNotification from "../../../components/SuccessNotification";

import { makeApiCall } from "../../../shared/makeApiCall";
import SimpleDeleteTemplate from "../../../components/ModalTemplates/SimpleDeleteTemplate";
import { convertCaseType, updateObjectInArray } from "../../../shared/utility";

import Select from "react-select";
import AddItemsToPoForm from "../../../components/forms/AddItemsToPoForm";

const CreatePo = (props) => {
  const { checkRequestError, userId } = props;

  useEffect(() => {
    document.title = "Create PO";

    fetchAllManagers();
    fetchAllSuppliers();
  }, []);

  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModel] = useState({
    actionMessage: "",
    actionFunc: null,
    itemId: 0,
    message: "",
    open: false,
    title: "",
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [products, setProducts] = useState([]);
  const [managers, setManagers] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });
  const [suppliers, setSuppliers] = useState([]);
  const [supplierChosen, setSupplierChosen] = useState(0);

  var pageNumber = 0;
  var totalPages = 0;
  var isLoading = true;

  const fetchAllManagers = async function () {
    const authData = {
      action: "fetchAllManagers",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setManagers(callResult.data.managers);
      fetchAllOpenOrders();
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchAllOpenOrders = async function () {
    isLoading = true;
    const authData = {
      action: "fetchOpenRequestsForAdmin",
      userId: userId,
      pageNumber: pageNumber,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      if (pageNumber > 0) {
        if (callResult.data.products.length > 0) {
          setProducts((products) => [...products, ...callResult.data.products]);
        }
      } else {
        totalPages = callResult.data.totalPages;
        setProducts(callResult.data.products);
      }

      setLoading(false);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        setLoading(false);
      }
    }
    isLoading = false;
  };

  const fetchAllSuppliers = async function () {
    const authData = {
      action: "fetchAllSuppliersForDropdown",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setSuppliers(callResult.data.suppliers);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const createPo = async function () {
    if (supplierChosen > 0) {
      setDisableSubmitButton(true);
      const authData = {
        action: "createPurchaseOrder",
        userId: userId,
        supplierId: supplierChosen,
        purchaseOrder: purchaseOrder,
      };
      let callResult = await makeApiCall(authData);

      if (callResult.success) {
        setShow({ show: true, title: "Purchase order created." });

        window.location.href = "/purchase-orders/" + callResult.data.poId;
      } else {
        const isLogout = checkRequestError(callResult.errorStatus);
        if (!isLogout) {
          setErrorModalOpen({
            open: true,
            title: "Sorry!",
            subtitle:
              "We could not create the purchase order, please try again.",
          });
        }
      }
      setDisableSubmitButton(false);
    } else {
      setErrorModalOpen({
        open: true,
        title: "Please choose a supplier and try again.",
        subtitle: "",
      });
    }
  };

  const additemsToPoFunc = (valuesForPo, prodIndex) => {
    console.log("valuesForPo", valuesForPo);

    var newProduct = {
      productId: 123,
      managerId: "",
      productId: "",
      items: {
        0: {
          totalAmount: 0,
          requests: [],
        },
        1: {
          totalAmount: 0,
          requests: [],
        },
        2: {
          totalAmount: 0,
          requests: [],
        },
        3: {
          totalAmount: 0,
          requests: [],
        },
        4: {
          totalAmount: 0,
          requests: [],
        },
      },
    };

    valuesForPo.cleanedRequestItems.forEach((product) => {
      newProduct = {
        ...newProduct,
        managerId: product.managerId,
        productId: product.items[0].productId,
      };

      product.items.forEach((item) => {
        const caseType = item.caseType;
        const totalAmount =
          newProduct.items[caseType].totalAmount + +item.newAmount;

        const itemObj = {
          totalAmount: totalAmount,
          requests: [
            ...newProduct.items[caseType].requests,
            {
              requestId: item.requestId,
              orderItemId: item.orderItemId,
              newAmount: item.newAmount,
              amountFullfilled: +item.amountFullfilled + +item.newAmount,
              managerId: item.userId,
            },
          ],
        };
        newProduct.items[caseType] = itemObj;
      });
    });
    console.log("newProduct", newProduct);

    const currentPurchaseOrder = [...purchaseOrder];
    currentPurchaseOrder[newProduct.productId] = newProduct;
    setPurchaseOrder(currentPurchaseOrder);

    //// NOW THAT WE CREATED THE PO, MAKE THE CHANGES TO THE PRODUCT ITSELF IN THE TABLE
    const currentProducts = [...products];
    const currentProduct = currentProducts[prodIndex];

    valuesForPo.cleanedRequestItems.forEach((poItem) => {
      currentProduct.requestItems.forEach(function (
        requestItem,
        index,
        theArray
      ) {
        if (requestItem.userId == poItem.managerId) {
          poItem.items.forEach((item) => {
            if (requestItem.requestId == item.requestId) {
              const newRequestItem = {
                ...requestItem,
                newAmount: item.newAmount,
              };

              theArray[index] = newRequestItem;
            }
          });
        }
      });
    });

    const payload = {
      index: prodIndex,
      item: currentProduct,
    };
    const updatedProducts = updateObjectInArray(currentProducts, payload);
    setProducts(updatedProducts);

    setSidePanelExtra({});
    setOpenSidePanel(false);
    setShow({ show: true, title: "Cart updated." });
  };

  console.log("purchaseOrder", purchaseOrder);

  const removeFromPo = (product, prodIndex) => {
    const updatedPurchaseOrder = purchaseOrder.filter(
      (prod) => prod.productId !== product.productId
    );
    setPurchaseOrder(updatedPurchaseOrder);

    const currentProducts = [...products];
    var newProduct = {
      ...currentProducts[prodIndex],
      addedToPo: false,
    };

    const payload = {
      index: prodIndex,
      item: newProduct,
    };
    const updatedProducts = updateObjectInArray(currentProducts, payload);
    setProducts(updatedProducts);
  };

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (!isLoading && pageNumber < totalPages) {
        pageNumber++;
        setLoading(true);
        fetchAllOpenOrders();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  var currCatId = -1;

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <Select
                  name="supplier"
                  options={suppliers}
                  onChange={(e) => setSupplierChosen(e.value)}
                  className="max-w-lg sm:max-w-xs z-50"
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:w-auto ml-2"
                  onClick={() => createPo()}
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Create PO"
                  )}
                </button>
              </div>
            </div>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  {loading && pageNumber == 10000 ? (
                    <div className="w-full items-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-10 w-10 text-indigo-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  ) : (
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table
                        className="min-w-full border-separate"
                        style={{ borderSpacing: 0 }}
                      >
                        <thead className="bg-white">
                          <tr className="divide-x divide-gray-200">
                            <th
                              scope="col"
                              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                            >
                              Product
                            </th>
                            {managers.map((manager) => (
                              <th
                                key={manager.managerId}
                                scope="col"
                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                              >
                                {manager.storeName}
                              </th>
                            ))}
                            <th
                              scope="col"
                              className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {products.map((product, prodIndex) => {
                            var htmlToReturn = (
                              <React.Fragment>
                                {product.categoryId !== currCatId ? (
                                  <tr
                                    key={product.productId}
                                    className="divide-x divide-y divide-gray-200"
                                  >
                                    <td
                                      className="whitespace-nowrap p-4 text-sm font-medium text-gray-900 font-bold"
                                      style={{
                                        border: ".1rem solid rgb(229 231 235)",
                                      }}
                                      colSpan={managers.length + 2}
                                    >
                                      {product.categoryName}
                                    </td>
                                  </tr>
                                ) : null}
                                <tr
                                  key={product.productId}
                                  className="divide-x divide-y divide-gray-200"
                                >
                                  <td
                                    className="whitespace-nowrap p-4 text-sm font-medium text-gray-900"
                                    style={{
                                      borderBottom:
                                        ".1rem solid rgb(229 231 235)",
                                    }}
                                  >
                                    <div className="flex items-center space-x-3">
                                      <div
                                        style={{
                                          backgroundColor:
                                            product.categoryColor,
                                        }}
                                        className="mx-2 w-5 h-5 rounded-full"
                                      ></div>

                                      {product.productName}
                                    </div>
                                  </td>
                                  {managers.map((manager, managerIndex) => (
                                    <td
                                      key={manager.managerId}
                                      className="whitespace-nowrap p-4 text-sm font-medium text-gray-900"
                                    >
                                      {product.requestItems.length > 0 ? (
                                        product.requestItems.map((request) => (
                                          <div key={request.orderItemId}>
                                            {request.userId ===
                                            manager.managerId ? (
                                              <div className="flex">
                                                <p>
                                                  {request.amountFullfilled}
                                                </p>
                                                {request.newAmount > 0 ? (
                                                  <>
                                                    +
                                                    <p style={{ color: "red" }}>
                                                      {request.newAmount}
                                                    </p>
                                                  </>
                                                ) : null}
                                                {"/"}
                                                {request.amount}{" "}
                                                {convertCaseType(
                                                  request.caseType
                                                )}
                                              </div>
                                            ) : (
                                              <div className="flex"></div>
                                            )}
                                          </div>
                                        ))
                                      ) : (
                                        <div className="flex"></div>
                                      )}
                                    </td>
                                  ))}
                                  <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900">
                                    {product.addedToPo ? (
                                      <button
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none sm:w-auto ml-2"
                                        onClick={() =>
                                          removeFromPo(product, prodIndex)
                                        }
                                      >
                                        Remove from PO
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:w-auto ml-2"
                                        onClick={() => {
                                          setSidePanelExtra({
                                            childComponent: (
                                              <AddItemsToPoForm
                                                prodIndex={prodIndex}
                                                managerIndex={prodIndex}
                                                additemsToPoFunc={
                                                  additemsToPoFunc
                                                }
                                                checkRequestError={
                                                  checkRequestError
                                                }
                                                managers={managers}
                                                productId={product.productId}
                                                requestItems={
                                                  product.requestItems
                                                }
                                                setOpen={setOpenSidePanel}
                                                setErrorModalOpen={
                                                  setErrorModalOpen
                                                }
                                                setShow={setShow}
                                                setSidePanelExtra={
                                                  setSidePanelExtra
                                                }
                                                subtitle=""
                                                title={product.productName}
                                                userId={userId}
                                              />
                                            ),
                                          });
                                          setOpenSidePanel(true);
                                        }}
                                        disabled={product.hasPo === 1}
                                      >
                                        {product.hasPo === 1
                                          ? "View PO"
                                          : "Add to PO"}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                            currCatId = product.categoryId;
                            return htmlToReturn;
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {loading ? (
                    <div className="w-full items-center mt-5">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-10 w-10 text-indigo-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <SimpleDeleteTemplate
          open={openDeleteModal.open}
          setOpen={setOpenDeleteModel}
          {...openDeleteModal}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(CreatePo);
