import React, { useState } from "react";

import { useForm, Form } from "../useForm";

import Input from "../controls/Input";

import { makeApiCall } from "../../shared/makeApiCall";

const initialFValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsPasswordForm(props) {
  const { checkRequestError, setErrorModalOpen, setShow, userId } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const updatePassword = async function () {
    setDisableSubmitButton(true);
    const authData = {
      action: "updatePassword",
      userId: userId,
      data: values,
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      setShow({ show: true, title: "Password updated." });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: callResult.data.subtitle,
        });
      }
      setSubmitError(callResult.data.subtitle);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      updatePassword();
    }
  };

  return (
    <>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Password
              </h3>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <Form onSubmit={handleSubmit}>
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <Input
                        label="Current Password"
                        name="currentPassword"
                        onChange={handleInputChange}
                        value={values.currentPassword}
                        type="password"
                        labelOn={true}
                      />
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <Input
                        label="Password"
                        name="newPassword"
                        onChange={handleInputChange}
                        value={values.newPassword}
                        type="password"
                        labelOn={true}
                      />
                    </div>
                    <div className="col-span-3 sm:col-span-2">
                      <Input
                        label="Confirm Password"
                        name="confirmPassword"
                        onChange={handleInputChange}
                        value={values.confirmPassword}
                        type="password"
                        labelOn={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                    disabled={disableSubmitButton}
                  >
                    {disableSubmitButton ? (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
