import React, { useEffect, useState } from "react";

import { useForm, Form } from "../useForm";

import Input from "../controls/Input";
import SidePanelHeader from "../SidePanelHeader";

import { makeApiCall } from "../../shared/makeApiCall";
import { convertCaseType, updateObjectInArray } from "../../shared/utility";

const initialFValues = {
  managerId: 0,
  productId: 0,
  requestItems: [],
};

export default function EditItemsToPoForm(props) {
  const {
    actionCall,
    loading,
    manager,
    prodIndex,
    productId,
    requestItems,
    setOpen,
    subtitle,
    title,
  } = props;

  console.log("loading", loading);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      actionCall(values, prodIndex);
    }
  };

  useEffect(() => {
    if (requestItems != null) {
      setValues({
        managerId: manager.managerId,
        productId: productId,
        requestItems: requestItems.items,
      });
    }
  }, [requestItems]);

  useEffect(() => {
    console.log("hey oggy loading", loading);
  }, [loading]);

  const handleCustomChange = (itemIndex, newAmount) => {
    const currentItems = [...values.requestItems];
    const currentItem = currentItems[itemIndex];

    const newItem = {
      ...currentItem,
      amount: newAmount,
    };
    const payload = {
      index: itemIndex,
      item: newItem,
    };
    const updatedItems = updateObjectInArray(currentItems, payload);

    setValues({ ...values, requestItems: updatedItems });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5 divide-y divide-gray-500">
              {values.requestItems.map((request, itemIndex) => (
                <div key={itemIndex} className="py-2">
                  <div className="mt-5">
                    <Input
                      label={convertCaseType(itemIndex.toString())}
                      labelOn={true}
                      name={"item-" + request.orderItemId}
                      onChange={(e) =>
                        handleCustomChange(itemIndex, e.target.value)
                      }
                      defaultValue={request.amount}
                      value={request.amount}
                      placeholder=""
                      explanation=""
                      type="number"
                      min="0"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {submitError ? (
        <div className="flex-shrink-0 px-4 py-4 flex">
          <span className="text-red-700">{submitError}</span>
        </div>
      ) : null}
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          disabled={disableSubmitButton}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Create"
          )}
        </button>
      </div>
    </Form>
  );
}
