import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  jwt: null,
  refreshToken: null,
  userId: null,
  error: null,
  name: "",
  storeName: "",
  loading: false,
  email: "",
  userType: 0,
  notifyMessage: null,
  cart: [],
};

const authStart = (state) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    jwt: action.jwt,
    refreshToken: action.refreshToken,
    userId: action.userId,
    error: null,
    loading: false,
    name: action.name,
    storeName: action.storeName,
    email: action.email,
    userType: action.userType,
  });
};

const authFail = (state, action) => {
  if (action.error.data) {
    return updateObject(state, {
      error: {
        title: action.error.data.title,
        subtitle: action.error.data.subtitle,
      },
      loading: false,
    });
  }
  return updateObject(state, {
    error: {
      title: "",
      subtitle: "We could not complete your request, please try again.",
    },
    loading: false,
  });
};

const authLogout = (state) => {
  return updateObject(state, { jwt: null, refreshToken: null, userId: null });
};

const myAccountUpdateRequestStart = (state) => {
  return updateObject(state, {
    error: null,
    notifyMessage: null,
    loading: true,
  });
};

const myAccountUpdateRequestSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    notifyMessage: action.data.message,
    name: action.data.returnData.name,
    storeName: action.data.returnData.storeName,
    email: action.data.returnData.email,
    website: action.data.returnData.website,
    loading: false,
    notifyMessage: action.data.message,
  });
};

const updateRequestFail = (state, action) => {
  let error = {
    title: "Oops.",
    subtitle: "We could not complete your request. Please try again.",
  };

  if (action.error.data) {
    error = {
      title: action.error.data.title,
      subtitle: action.error.data.subtitle,
    };
  }

  return updateObject(state, {
    error: error,
    notifyMessage: null,
    loading: false,
  });
};

const setNotificationMessageNull = (state) => {
  const updatedState = {
    notifyMessage: null,
    error: null,
  };

  return updateObject(state, updatedState);
};

const addToCart = (state, action) => {
  const updatedCart = [...state.cart, action.newItem];
  return updateObject(state, {
    cart: updatedCart,
  });
};

const updateUserProfile = (state, action) => {
  return updateObject(state, {
    name: action.data.name,
    storeName: action.data.storeName,
    email: action.data.email,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);

    case actionTypes.MY_ACCOUNT_UPDATE_REQUEST_START:
      return myAccountUpdateRequestStart(state, action);
    case actionTypes.MY_ACCOUNT_UPDATE_REQUEST_SUCCESS:
      return myAccountUpdateRequestSuccess(state, action);
    case actionTypes.MY_ACCOUNT_UPDATE_REQUEST_FAIL:
      return updateRequestFail(state, action);

    case actionTypes.SET_AUTH_NOTIFY_MESSAGE_NULL:
      return setNotificationMessageNull(state);

    case actionTypes.ADD_TO_CART:
      return addToCart(state, action);

    case actionTypes.UPDATE_USER_PROFILE:
      return updateUserProfile(state, action);

    default:
      return state;
  }
};

export default reducer;
