import React, { useState } from "react";

import SidePanelHeader from "../SidePanelHeader";

import { convertCaseType, formatDate } from "../../shared/utility";

export default function ManagerOrderItems(props) {
  const { order, setOpen, subtitle, title } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  return (
    <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mx-4">
          <div className="px-4 divide-y divide-gray-500 sm:px-6">
            <div className="grid grid-cols-1 gap-4 space-y-2 pt-6 pb-5">
              <div>
                <p className="mt-1 max-w-2xl text-md text-gray-900">
                  Date created: {formatDate(order.addedDate)}
                </p>
              </div>
              {/* <div>
                <p className="mt-1 max-w-2xl text-md text-gray-900">Status:</p>
              </div> */}
            </div>
          </div>
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Product
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Amount ordered
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {order.items.map((item, index) => (
                <tr key={item.orderItemId}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {item.productName}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {item.amount} {convertCaseType(item.caseType)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="m-4">
          <p>Notes: </p>
          <textarea
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg sm:max-w-xs"
            name="notes"
            disabled={true}
            value={order.notes}
            rows="4"
          />
        </div>
      </div>
      {submitError ? (
        <div className="flex-shrink-0 px-4 py-4 flex">
          <span className="text-red-700">{submitError}</span>
        </div>
      ) : null}
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setOpen(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
}
