import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ErrorModal from "../../../components/ModalTemplates/EmptyModal";
import SidePanel from "../../../components/SidePanel";
import SuccessNotification from "../../../components/SuccessNotification";

import { Link } from "react-router-dom";

import { makeApiCall } from "../../../shared/makeApiCall";
import ActionModal from "../../../components/ActionModal";
import CreateProductForm from "../../../components/forms/CreateProductForm";
import SimpleDeleteTemplate from "../../../components/ModalTemplates/SimpleDeleteTemplate";
import ImportProductsModal from "../../../components/ModalTemplates/ImportProductsModal";

const Products = (props) => {
  const { checkRequestError, userId } = props;

  useEffect(() => {
    document.title = "Products";

    fetchAllProducts();
    fetchAllCategoriesForDropdown();
  }, []);

  const [openActionModal, setOpenActionModal] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [categories, setCategories] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModel] = useState({
    actionMessage: "",
    actionFunc: null,
    itemId: 0,
    message: "",
    open: false,
    title: "",
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const searchRef = useRef();

  var pageNumber = 1;
  var searchValue = null;
  var totalPages = 0;

  var isLoading = true;

  const fetchAllProducts = async function () {
    if (pageNumber == 1) {
      setLoading(true);
      if (searchValue != null) {
        setProducts([]);
      }
    }

    isLoading = true;
    const authData = {
      action: "fetchAllProducts",
      userId: userId,
      search: searchValue,
      pageNumber: pageNumber,
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      if (pageNumber > 1) {
        if (callResult.data.products.length > 0) {
          setProducts((products) => [...products, ...callResult.data.products]);
        }
      } else {
        totalPages = callResult.data.total;
        setProducts(callResult.data.products);
      }
      setLoading(false);
      isLoading = false;
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        isLoading = false;
        setLoading(false);
      }
    }
  };

  const fetchAllCategoriesForDropdown = async function () {
    const authData = {
      action: "fetchAllCategoriesForDropdown",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setCategories(callResult.data.categories);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const deleteProductFunc = async function (productId) {
    const authData = {
      action: "deleteProduct",
      userId: userId,
      productId: productId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setOpenDeleteModel({ open: false });
      setShow({ show: true, title: "Product deleted." });
      const updatedProducts = products.filter(
        (product) => product.productId !== productId
      );
      setProducts(updatedProducts);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not delete the product, please try again.",
        });
      }
    }
  };

  const handleSearch = () => {
    searchValue = searchRef.current.value;

    pageNumber = 1;
    totalPages = 1;
    if (searchValue.length >= 3) {
      //   fetchAllProducts();
    }
  };

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                {/* <h1 className="text-xl font-semibold text-gray-900">
                  Products
                </h1> */}
                <input
                  type="search"
                  name="searchProduct"
                  id="searchProduct"
                  className="block w-1/3 mt-2 rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300"
                  placeholder="Search"
                  onChange={handleSearch}
                  ref={searchRef}
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500"
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-400 hover:bg-green-500 ml-2"
                  onClick={() => {
                    setOpenActionModal({
                      open: true,
                      setOpen: setOpenActionModal,
                      childComponent: (
                        <ImportProductsModal
                          fetchAllProducts={fetchAllProducts}
                          importType={0}
                          open={openActionModal}
                          setOpenActionModal={setOpenActionModal}
                          setShow={setShow}
                          userId={userId}
                        />
                      ),
                    });
                  }}
                >
                  Import
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto ml-2"
                  onClick={() => {
                    setSidePanelExtra({
                      childComponent: (
                        <CreateProductForm
                          categories={categories}
                          checkRequestError={checkRequestError}
                          products={products}
                          product={null}
                          fetchAllProducts={fetchAllProducts}
                          isEdit={false}
                          setProducts={setProducts}
                          setOpen={setOpenSidePanel}
                          setShow={setShow}
                          setErrorModalOpen={setErrorModalOpen}
                          setSidePanelExtra={setSidePanelExtra}
                          subtitle="Fill in the information below to add a new product."
                          title="Add new product"
                          userId={userId}
                        />
                      ),
                    });
                    setOpenSidePanel(true);
                  }}
                >
                  Add product
                </button>
              </div>
            </div>
            {categories.map((category) => (
              <div
                className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
                key={category.value}
              >
                <h1 className="text-xl font-semibold p-2">{category.label}</h1>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        SKU
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Category
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {products.map((product) => (
                      <tr key={product.productId}>
                        {category.value == product.categoryId ? (
                          <>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                              {product.productName}
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Description</dt>
                                <dd className="mt-1 truncate text-gray-700">
                                  {product.productDescription}
                                </dd>
                              </dl>
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Category</dt>

                                <dd className="mt-1 truncate text-gray-700">
                                  {product.categoryName}
                                </dd>
                              </dl>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {product.sku}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {product.productDescription}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {product.categoryName}
                            </td>

                            <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <button
                                className="text-indigo-600 hover:text-indigo-900"
                                onClick={() => {
                                  setSidePanelExtra({
                                    childComponent: (
                                      <CreateProductForm
                                        categories={categories}
                                        checkRequestError={checkRequestError}
                                        products={products}
                                        product={product}
                                        fetchAllProducts={fetchAllProducts}
                                        isEdit={true}
                                        setProducts={setProducts}
                                        setOpen={setOpenSidePanel}
                                        setShow={setShow}
                                        setErrorModalOpen={setErrorModalOpen}
                                        setSidePanelExtra={setSidePanelExtra}
                                        subtitle={null}
                                        title={"Editing " + product.productName}
                                        userId={userId}
                                      />
                                    ),
                                  });
                                  setOpenSidePanel(true);
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="text-indigo-600 hover:text-indigo-900 ml-2"
                                onClick={() => {
                                  setOpenDeleteModel({
                                    actionMessage: "Delete",
                                    actionFunc: deleteProductFunc,
                                    itemId: product.productId,
                                    message:
                                      "Are you sure you want to delete  " +
                                      product.productName +
                                      "?",
                                    open: true,
                                    title:
                                      "Delete " + product.productName + "?",
                                  });
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>

        <ActionModal
          childComponent={openActionModal.childComponent}
          open={openActionModal.open}
          setOpen={setOpenActionModal}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SimpleDeleteTemplate
          open={openDeleteModal.open}
          setOpen={setOpenDeleteModel}
          {...openDeleteModal}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Products);
