import React, { useEffect, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../store/actions/index";

import AdminLayout from "../layouts/Admin.js";
import AuthLayout from "../layouts/Auth.js";

import ErrorBoundary from "../hoc/ErrorBoundary.js";

import { withRouter } from "../shared/utility";

import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import PasswordReset from "../pages/auth/PasswordReset";

const App = (props) => {
  const { onTryAutoSignup } = props;

  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  let routes = null;

  const jwt = localStorage.getItem("jwt");

  let authRedirect = null;
  if (jwt) {
    if (props.isAuthenticated) {
      routes = (
        <Routes>
          <Route path="*" element={<AdminLayout />} />
        </Routes>
      );
    }
  } else {
    routes = (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/password/forgot" element={<ForgotPassword />} />
        <Route path="/password/resetpassword" element={<AuthLayout />} />
        <Route
          path="/password/resetpassword/:confirmationId"
          element={<PasswordReset />}
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  return (
    <div className="min-h-screen">
      {authRedirect}
      <ErrorBoundary>
        <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.jwt !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
