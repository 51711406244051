import React from "react";
import { createPopper } from "@popperjs/core";

const TooltipButton = (props) => {
  const { explanation } = props;

  const [tooltipShow, setTooltipShow] = React.useState(false);
  const btnRef = React.createRef();
  const tooltipRef = React.createRef();
  const openLeftTooltip = () => {
    createPopper(btnRef.current, tooltipRef.current, {
      placement: "top",
    });
    setTooltipShow(true);
  };
  const closeLeftTooltip = () => {
    setTooltipShow(false);
  };
  return (
    <>
      <button
        type="button"
        onMouseEnter={openLeftTooltip}
        onMouseLeave={closeLeftTooltip}
        ref={btnRef}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          viewBox="0 0 20 20"
          fill="gray"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div
        className={
          (tooltipShow ? "" : "hidden ") +
          "border-0 mt-3 block font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
        }
        ref={tooltipRef}
        style={{ backgroundColor: "#676B75" }}
      >
        <div>
          <div className="text-white p-3 text-center">{explanation}</div>
        </div>
      </div>
    </>
  );
};

export default TooltipButton;
