import React, { useEffect, useRef, useState, Fragment } from "react";
import { connect } from "react-redux";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/solid";
import { PlusIcon, SearchIcon } from "@heroicons/react/outline";
import { Menu, Transition } from "@headlessui/react";

import ErrorModal from "../../../components/ModalTemplates/EmptyModal";
import SidePanel from "../../../components/SidePanel";
import SuccessNotification from "../../../components/SuccessNotification";
import EditPoForm from "../../../components/forms/EditPoForm";

import { makeApiCall } from "../../../shared/makeApiCall";
import SimpleDeleteTemplate from "../../../components/ModalTemplates/SimpleDeleteTemplate";
import { formatDate } from "../../../shared/utility";
import { Link } from "react-router-dom";
import { config } from "../../../constants";

const PurchaseOrders = (props) => {
  const { checkRequestError, userId } = props;

  useEffect(() => {
    document.title = "Purchase Orders";

    fetchAllPurchaseOrders();
  }, []);

  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModel] = useState({
    actionMessage: "",
    actionFunc: null,
    itemId: 0,
    message: "",
    open: false,
    title: "",
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const searchRef = useRef();

  var pageNumber = 1;
  var searchValue = null;
  var totalPages = 0;

  var isLoading = true;

  const deletePurchaseOrderFunc = async function (purchaseOrderId) {
    const authData = {
      action: "deletePurchaseOrder",
      userId: userId,
      purchaseOrderId: purchaseOrderId,
    };
    setOpenDeleteModel({ open: false });
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setShow({ show: true, title: "Purchase order deleted." });
      pageNumber = 1;
      fetchAllPurchaseOrders();
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not delete the supplier, please try again.",
        });
      }
    }
  };

  const fetchAllPurchaseOrders = async function () {
    if (pageNumber == 1) {
      setLoading(true);
      if (searchValue != null) {
        setPurchaseOrders([]);
      }
    }

    isLoading = true;
    const authData = {
      action: "fetchAllPurchaseOrders",
      userId: userId,
      search: searchValue,
      pageNumber: pageNumber,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      if (pageNumber > 1) {
        if (callResult.data.purchaseOrders.length > 0) {
          setPurchaseOrders((purchaseOrders) => [
            ...purchaseOrders,
            ...callResult.data.purchaseOrders,
          ]);
        }
      } else {
        totalPages = callResult.data.total;
        setPurchaseOrders(callResult.data.purchaseOrders);
      }
      setLoading(false);
      isLoading = false;
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        isLoading = false;
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto"></div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Supplier
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Added date
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only"></span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {purchaseOrders.map((purchaseOrder) => (
                    <tr key={purchaseOrder.poId}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {purchaseOrder.poId}
                      </td>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {purchaseOrder.supplierName}
                      </td>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {formatDate(purchaseOrder.addedDate)}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex">
                        <a
                          href={
                            "mailto:" +
                            purchaseOrder.supplierEmail +
                            "?subject=Pavao Meats Purchase Order"
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className={
                            "cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto"
                          }
                        >
                          Send Email
                        </a>
                        <a
                          onClick={() =>
                            window.open(
                              config.url.API_URL +
                                "/downloadPoInvoice.php?key=" +
                                purchaseOrder.poId,
                              "_blank"
                            )
                          }
                          className={
                            "cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 sm:w-auto ml-2"
                          }
                        >
                          Download PO
                        </a>
                        <Link
                          className={
                            "cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 sm:w-auto ml-2"
                          }
                          to={"/purchase-orders/" + purchaseOrder.poId}
                        >
                          View
                        </Link>

                        <button
                          className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 sm:w-auto ml-2"
                          onClick={() => {
                            setOpenDeleteModel({
                              actionMessage: "Delete",
                              actionFunc: deletePurchaseOrderFunc,
                              itemId: purchaseOrder.poId,
                              message:
                                "Are you sure you want to delete the purchase order?",
                              open: true,
                              title: "Delete",
                            });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <SimpleDeleteTemplate
          open={openDeleteModal.open}
          setOpen={setOpenDeleteModel}
          {...openDeleteModal}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(PurchaseOrders);
