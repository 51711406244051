import React, { useEffect, useState } from "react";

import { useForm, Form } from "../useForm";

import Input from "../controls/Input";
import SidePanelHeader from "../SidePanelHeader";

import { makeApiCall } from "../../shared/makeApiCall";

import Select from "react-select";

const initialFValues = {
  productId: "",
  productName: "",
  productDescription: "",
  categoryArray: [],
};

export default function CreateProductForm(props) {
  const {
    categories,
    checkRequestError,
    product,
    fetchAllProducts,
    isEdit,
    setOpen,
    setShow,
    setSidePanelExtra,
    subtitle,
    title,
    userId,
  } = props;

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const addProductFunc = async function (isEdit, data) {
    setSubmitError(null);
    setDisableSubmitButton(true);
    const authData = {
      action: isEdit ? "updateProduct" : "addProduct",
      userId: userId,
      data: data,
    };
    let callResult = await makeApiCall(authData);
    setDisableSubmitButton(false);
    if (callResult.success) {
      setSidePanelExtra({});
      setOpen(false);
      setShow({ show: true, title: "Product updated." });
      fetchAllProducts();
      if (isEdit) {
      } else {
        setSidePanelExtra({});
        setOpen(false);
        setShow({ show: true, title: "Product added." });
        fetchAllProducts();
      }
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setSubmitError(callResult.data.subtitle);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addProductFunc(isEdit, values);
    }
  };

  useEffect(() => {
    if (product != null) {
      setValues(product);
    }
  }, [product]);

  const convertToDefEventPara = (name, value) => {
    return {
      target: {
        name,
        value,
      },
    };
  };

  const handleCategoryRuleChange = (newValue) => {
    const newValues = {
      categoryId: newValue.categoryId,
      categoryName: newValue.categoryName,
    };

    handleInputChange(convertToDefEventPara("categoryArray", newValue));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="flex-1 h-0 overflow-y-auto">
        <SidePanelHeader subtitle={subtitle} title={title} setOpen={setOpen} />
        <div className="justify-between">
          <div className="px-4 divide-y divide-gray-500 sm:px-6">
            <div className="grid grid-cols-2 gap-4 space-y-3 pt-6 pb-5">
              <div className="sm:col-span-2">
                <Input
                  label="Name"
                  name="productName"
                  onChange={handleInputChange}
                  value={values.productName}
                  labelOn={true}
                  explanation="Name of your product"
                />
              </div>
              <div className="sm:col-span-2">
                <Input
                  label="SKU"
                  name="sku"
                  onChange={handleInputChange}
                  value={values.sku}
                  labelOn={true}
                />
              </div>
              <div className="sm:col-span-2">
                <Select
                  name="categories"
                  options={categories}
                  onChange={handleCategoryRuleChange}
                  defaultValue={product ? product.categoryArray : []}
                  placeholder=""
                  className="max-w-lg sm:max-w-xs"
                />
              </div>
              <div className="sm:col-span-2">
                <div className="flex space-x-2">
                  <label
                    htmlFor="productDescription"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Description
                  </label>
                </div>
                <textarea
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg sm:max-w-xs"
                  name="productDescription"
                  onChange={handleInputChange}
                  value={values.productDescription}
                  rows="4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {submitError ? (
        <div className="flex-shrink-0 px-4 py-4 flex">
          <span className="text-red-700">{submitError}</span>
        </div>
      ) : null}
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          disabled={disableSubmitButton}
        >
          {disableSubmitButton ? (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            "Create"
          )}
        </button>
      </div>
    </Form>
  );
}
