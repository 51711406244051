import React from "react";
import TooltipButton from "../../components/TooltipButton";

export default function Input(props) {
  const {
    classes,
    label,
    name,
    onChange,
    type,
    value,
    labelOn,
    error,
    explanation,
    ...other
  } = props;

  return (
    <div>
      {labelOn ? (
        <div className="flex space-x-2">
          <label
            htmlFor={name}
            className="block text-sm font-medium text-gray-900"
          >
            {label}
          </label>
          {explanation ? <TooltipButton explanation={explanation} /> : null}
        </div>
      ) : null}
      <div className="mt-1">
        <input
          type={type}
          name={name}
          id={name}
          className={
            classes
              ? classes
              : "mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg sm:max-w-xs"
          }
          value={value}
          onChange={onChange}
          {...other}
        />
        {error ? <p className="font-medium text-red-500">{error}</p> : null}
      </div>
    </div>
  );
}
