import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import {
  useLocation,
  useNavigate,
  Link,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  MenuAlt2Icon,
  ShoppingBagIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

import * as actions from "../store/actions/index";

import Categories from "../pages/AdminArea/categories/Categories";
import CreatePo from "../pages/AdminArea/purchaseOrders/CreatePo";
import Managers from "../pages/AdminArea/managers/Managers";
import Products from "../pages/AdminArea/products/Products";
import PurchaseOrders from "../pages/AdminArea/purchaseOrders/PurchaseOrders";
import Settings from "../pages/settings/Settings";
import Suppliers from "../pages/AdminArea/suppliers/Suppliers";

import routes from "./routes";
import Shop from "../pages/ManagerArea/shop/Shop";
import MyOrders from "../pages/ManagerArea/myOrders/MyOrders";
import ViewPurchaseOrder from "../pages/AdminArea/purchaseOrders/purchaseOrder/ViewPurchaseOrder";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Admin = (props) => {
  const { userId, userType } = props;

  let navigate = useNavigate();

  const { onLogout } = props;
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const changeDestination = (destination) => {
    navigate(destination);
  };

  const getRoutes = routes(userType, location.pathname);
  const routesArray = getRoutes["routes"];
  const pageHeader = getRoutes["title"];

  const checkRequestError = (errorStatus) => {
    if (errorStatus === 401) {
      onLogout();
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://pavaomeats.com/wp-content/uploads/2020/06/logo.png"
                      alt="EuroButcher"
                    />
                    <h1 className="text-xl font-semibold text-white">
                      Pavao Meats Admin
                    </h1>
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {routesArray.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-indigo-800 text-white"
                              : "text-indigo-100 hover:bg-indigo-600",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 bg-indigo-700 overflow-y-auto">
            <div className="items-center px-4">
              <img
                className="h-16 w-auto"
                src="https://pavaomeats.com/wp-content/uploads/2020/06/logo.png"
                alt="EuroButcher"
              />
              <h1 className="text-xl font-semibold text-white mt-2">
                Pavao Meats Admin
              </h1>
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {routesArray.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current
                        ? "bg-indigo-800 text-white"
                        : "text-indigo-100 hover:bg-indigo-600",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    {/* <item.icon
                      className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
                      aria-hidden="true"
                    /> */}
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex items-center sm:px-6 lg:px-8">
                <div className="w-full flex md:ml-0">
                  <h1 className="text-xl font-semibold text-gray-900 px-4">
                    {pageHeader}
                  </h1>
                </div>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="bg-white p-1 rounded-full text-gray-800 hover:text-gray-500"
                >
                  <span className="sr-only">View cart</span>
                  <ShoppingBagIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item key={0}>
                        {({ active }) => (
                          <button
                            type="button"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={() => changeDestination("/settings")}
                          >
                            Settings
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item key={1}>
                        {({ active }) => (
                          <button
                            type="button"
                            className="block px-4 py-2 text-sm text-gray-700"
                            onClick={onLogout}
                          >
                            Log out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          {userType === "1" ? (
            <Routes>
              <Route
                path="/categories"
                element={<Categories checkRequestError={checkRequestError} />}
              />
              <Route
                path="/create-po"
                element={<CreatePo checkRequestError={checkRequestError} />}
              />
              <Route
                path="/managers"
                element={<Managers checkRequestError={checkRequestError} />}
              />
              <Route
                path="/products"
                element={<Products checkRequestError={checkRequestError} />}
              />
              <Route
                path="/purchase-orders"
                element={
                  <PurchaseOrders checkRequestError={checkRequestError} />
                }
              />
              <Route
                path="/purchase-orders/:purchaseOrderId"
                element={
                  <ViewPurchaseOrder checkRequestError={checkRequestError} />
                }
              />
              <Route
                path="/settings/"
                element={<Settings checkRequestError={checkRequestError} />}
              />
              <Route
                path="/suppliers"
                element={<Suppliers checkRequestError={checkRequestError} />}
              />

              <Route path="*" element={<Navigate to="/create-po" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route
                path="/create-order"
                element={<Shop checkRequestError={checkRequestError} />}
              />
              <Route
                path="/my-orders"
                element={<MyOrders checkRequestError={checkRequestError} />}
              />
              <Route
                path="/settings/"
                element={<Settings checkRequestError={checkRequestError} />}
              />

              <Route
                path="*"
                element={<Navigate to="/create-order" replace />}
              />
            </Routes>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { onLogout: () => dispatch(actions.logout()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
