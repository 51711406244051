export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const MAKE_ADMIN_REQUEST_START = "MAKE_ADMIN_REQUEST_START";
export const ADD_ADMIN_REQUEST_SUCCESS = "ADD_ADMIN_REQUEST_SUCCESS";
export const UPDATE_ADMIN_REQUEST_SUCCESS = "UPDATE_ADMIN_REQUEST_SUCCESS";
export const MAKE_ADMIN_REQUEST_FAILED = "MAKE_ADMIN_REQUEST_FAILED";
export const DELETE_ADMIN_REQUEST_SUCCESS = "DELETE_ADMIN_REQUEST_SUCCESS";

export const AUTH_RESET_REQUEST_START = "AUTH_RESET_REQUEST_START";
export const AUTH_RESET_REQUEST_SUCCESS = "AUTH_RESET_REQUEST_SUCCESS";
export const AUTH_RESET_REQUEST_FAIL = "AUTH_RESET_REQUEST_FAIL";

export const MY_ACCOUNT_UPDATE_REQUEST_START =
  "MY_ACCOUNT_UPDATE_REQUEST_START";
export const MY_ACCOUNT_UPDATE_REQUEST_SUCCESS =
  "MY_ACCOUNT_UPDATE_REQUEST_SUCCESS";
export const MY_ACCOUNT_UPDATE_REQUEST_FAIL = "MY_ACCOUNT_UPDATE_REQUEST_FAIL";

export const PASSWORD_UPDATE_REQUEST_START = "PASSWORD_UPDATE_REQUEST_START";
export const PASSWORD_UPDATE_REQUEST_SUCCESS =
  "PASSWORD_UPDATE_REQUEST_SUCCESS";
export const PASSWORD_UPDATE_REQUEST_FAIL = "PASSWORD_UPDATE_REQUEST_FAIL";

export const SET_AUTH_NOTIFY_MESSAGE_NULL = "SET_AUTH_NOTIFY_MESSAGE_NULL";

export const ADD_TO_CART = "ADD_TO_CART";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
