import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorModal from "../../../../components/ModalTemplates/EmptyModal";
import SuccessNotification from "../../../../components/SuccessNotification";
import EditItemsToPoForm from "../../../../components/forms/EditItemsToPoForm";

import { config } from "../../../../constants";

import { updateObjectInArray } from "../../../../shared/utility";
import SidePanel from "../../../../components/SidePanel";

import { makeApiCall } from "../../../../shared/makeApiCall";
import {
  convertCaseType,
  formatDate,
  getLastItem,
} from "../../../../shared/utility";

const ViewPurchaseOrder = (props) => {
  const { checkRequestError, userId } = props;

  const { pathname } = useLocation();

  const poId = getLastItem(pathname);

  useEffect(() => {
    document.title = "Loading...";

    fetchAllManagers();
    fetchPurchaseOrderDetails();
  }, []);

  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [managers, setManagers] = useState([]);
  const [products, setProducts] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const fetchAllManagers = async function () {
    const authData = {
      action: "fetchAllManagers",
      userId: userId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setManagers(callResult.data.managers);
      fetchPurchaseOrderProducts();
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  const fetchPurchaseOrderProducts = async function () {
    const authData = {
      action: "fetchPurchaseOrderProducts",
      userId: userId,
      poId: poId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setProducts(callResult.data.products);
      setLoading(false);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        setLoading(false);
      }
    }
  };

  const fetchPurchaseOrderDetails = async function () {
    const authData = {
      action: "viewPurchaseOrder",
      userId: userId,
      poId: poId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      document.title =
        "Purchase Order - #" + callResult.data.purchaseOrder.poId;
      setPurchaseOrder(callResult.data.purchaseOrder);
      setLoading(false);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        setLoading(false);
      }
    }
  };

  const updatePoItems = async function (data) {
    setLoading(true);

    const authData = {
      action: "updatePoItems",
      userId: userId,
      poId: poId,
      poData: data,
    };
    let callResult = await makeApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setProducts([]);
      fetchPurchaseOrderProducts();
      setSidePanelExtra({
        childComponent: null,
      });
      setOpenSidePanel(true);
      setShow({ show: true, title: callResult.data.title });
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
      }
    }
  };

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:items-center">
              <div className="sm:flex">
                <div className="sm:flex-auto">
                  <h1 className="text-md text-gray-900">
                    Purchase Order #:{" "}
                    <font className="font-semibold">{purchaseOrder.poId}</font>
                  </h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <a
                    href={
                      "mailto:" +
                      purchaseOrder.supplierEmail +
                      "?subject=Pavao Meats Purchase Order"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto ml-2 cursor-pointer"
                  >
                    Send Email
                  </a>
                  <a
                    type="button"
                    className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto ml-2 cursor-pointer"
                    onClick={() =>
                      window.open(
                        config.url.API_URL +
                          "/downloadPoInvoice.php?key=" +
                          poId,
                        "_blank"
                      )
                    }
                  >
                    Download PO
                  </a>
                </div>
              </div>
              <h1 className="text-md text-gray-900">
                Supplier:{" "}
                <font className="font-semibold">
                  {purchaseOrder.supplierName}
                </font>
              </h1>
              <h1 className="text-md text-gray-900">
                Date created:{" "}
                <font className="font-semibold">
                  {formatDate(purchaseOrder.addedDate)}
                </font>
              </h1>
            </div>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table
                      className="min-w-full border-separate"
                      style={{ borderSpacing: 0 }}
                    >
                      <thead className="bg-white">
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                          >
                            Product
                          </th>
                          {managers.map((manager) => (
                            <th
                              key={manager.managerId}
                              scope="col"
                              className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                            >
                              {manager.storeName}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {products.map((product, prodIndex) => (
                          <tr
                            key={product.productId}
                            className="divide-x divide-y divide-gray-200"
                            style={{
                              borderBottom: ".1rem solid rgb(229, 231, 235)",
                            }}
                          >
                            <td className="whitespace-nowrap p-4 text-sm flex font-medium text-gray-900 ">
                              <div
                                style={{
                                  backgroundColor: product.categoryColor,
                                }}
                                className="mx-2 w-5 h-5 rounded-full"
                              ></div>
                              {product.productName}
                            </td>
                            {managers.map((manager, managerIndex) => (
                              <td
                                key={manager.managerId}
                                className="whitespace-nowrap p-4 text-sm font-medium text-gray-900"
                              >
                                {product.requestItems.length > 0 ? (
                                  product.requestItems.map((request) => (
                                    <div key={request.poItemId}>
                                      {request.managerId ===
                                      manager.managerId ? (
                                        <div>
                                          {request.hasItems == true ? (
                                            request.items.map(
                                              (singleItem, index) => (
                                                <>
                                                  {singleItem.amount > 0 ? (
                                                    <div
                                                      className="cursor-pointer"
                                                      onClick={() => {
                                                        setSidePanelExtra({
                                                          childComponent: (
                                                            <EditItemsToPoForm
                                                              actionCall={
                                                                updatePoItems
                                                              }
                                                              loading={loading}
                                                              manager={manager}
                                                              prodIndex={
                                                                prodIndex
                                                              }
                                                              productId={
                                                                product.productId
                                                              }
                                                              requestItems={
                                                                request
                                                              }
                                                              setOpen={
                                                                setOpenSidePanel
                                                              }
                                                              setErrorModalOpen={
                                                                setErrorModalOpen
                                                              }
                                                              setSidePanelExtra={
                                                                setSidePanelExtra
                                                              }
                                                              subtitle={
                                                                manager.storeName
                                                              }
                                                              title={
                                                                product.productName
                                                              }
                                                            />
                                                          ),
                                                        });
                                                        setOpenSidePanel(true);
                                                      }}
                                                    >
                                                      {singleItem.amount}{" "}
                                                      {convertCaseType(
                                                        index.toString()
                                                      )}
                                                    </div>
                                                  ) : null}
                                                </>
                                              )
                                            )
                                          ) : (
                                            <div>
                                              <button
                                                className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 mt-5 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto cursor-pointer"
                                                onClick={() => {
                                                  setSidePanelExtra({
                                                    childComponent: (
                                                      <EditItemsToPoForm
                                                        actionCall={
                                                          updatePoItems
                                                        }
                                                        loading={loading}
                                                        manager={manager}
                                                        prodIndex={prodIndex}
                                                        productId={
                                                          product.productId
                                                        }
                                                        requestItems={request}
                                                        setOpen={
                                                          setOpenSidePanel
                                                        }
                                                        setErrorModalOpen={
                                                          setErrorModalOpen
                                                        }
                                                        setSidePanelExtra={
                                                          setSidePanelExtra
                                                        }
                                                        subtitle={
                                                          manager.storeName
                                                        }
                                                        title={
                                                          product.productName
                                                        }
                                                        userId={userId}
                                                      />
                                                    ),
                                                  });
                                                  setOpenSidePanel(true);
                                                }}
                                              >
                                                Add items
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      ) : null}
                                    </div>
                                  ))
                                ) : (
                                  <div>asd</div>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(ViewPurchaseOrder);
