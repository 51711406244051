import * as actionTypes from "./actionTypes";

import { config } from "../../constants";

import axiosRegular from "../../axios";
import axios from "axios";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (
  jwt,
  refreshToken,
  expirationDate,
  userId,
  name,
  storeName,
  email,
  userType
) => {
  localStorage.setItem("jwt", jwt);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("expirationDate", expirationDate);
  localStorage.setItem("userId", userId);
  localStorage.setItem("name", name);
  localStorage.setItem("storeName", storeName);
  localStorage.setItem("email", email);
  localStorage.setItem("userType", userType);

  return {
    type: actionTypes.AUTH_SUCCESS,
    jwt: jwt,
    refreshToken: refreshToken,
    userId: userId,
    name: name,
    storeName: storeName,
    email: email,
    userType: userType,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  localStorage.removeItem("name");
  localStorage.removeItem("storeName");
  localStorage.removeItem("email");
  localStorage.removeItem("userType");

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationDateout) => {
  //   return (dispatch) => {
  //     setTimeout(() => {
  //       dispatch(logout());
  //     }, expirationDateout * 1000);
  //   };
};

export const auth = (email, password, isForgotPassword) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password,
      action: isForgotPassword ? "forgotPassword" : "login",
    };
    let url = config.url.API_URL + "/apiEndPoint.php";

    axios
      .post(url, authData)
      .then((response) => {
        const expirationDate = new Date(
          new Date().getTime() + response.data.expiresIn * 1000
        );

        dispatch(
          authSuccess(
            response.data.jwt,
            response.data.refreshToken,
            response.data.expirationDate,
            response.data.userId,
            response.data.name,
            response.data.storeName,
            response.data.email,
            response.data.userType
          )
        );
      })
      .catch((err) => {
        dispatch(authFail(err.response));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const jwt = localStorage.getItem("jwt");
    const refreshToken = localStorage.getItem("refreshToken");
    if (!jwt) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      // if (expirationDate <= new Date()) {
      //   dispatch(logout());
      // } else {
      const userId = localStorage.getItem("userId");
      const name = localStorage.getItem("name");
      const storeName = localStorage.getItem("storeName");
      const email = localStorage.getItem("email");
      const userType = localStorage.getItem("userType");

      dispatch(
        authSuccess(
          jwt,
          refreshToken,
          expirationDate,
          userId,
          name,
          storeName,
          email,
          userType
        )
      );
      // dispatch(
      //   checkAuthTimeout(
      //     (expirationDate.getTime() - new Date().getTime()) / 1000
      //   )
      // );
      // }
    }
  };
};

export const myAccountUpdateRequestStart = () => {
  return {
    type: actionTypes.MY_ACCOUNT_UPDATE_REQUEST_START,
  };
};

export const myAccountUpdateRequestSuccess = (data) => {
  return {
    type: actionTypes.MY_ACCOUNT_UPDATE_REQUEST_SUCCESS,
    data: data,
  };
};

export const myAccountUpdateRequestFail = (error) => {
  return {
    type: actionTypes.MY_ACCOUNT_UPDATE_REQUEST_FAIL,
    error: error,
  };
};

export const makeMyAccountUpdateRequest = (userId, jwt, data) => {
  return (dispatch) => {
    dispatch(myAccountUpdateRequestStart());
    const authData = {
      userId: userId,
      jwt: jwt,
      data: data,
      action: "myAccountUpdate",
    };
    let url = config.url.API_URL + "/apiEndPoint.php";
    axios
      .post(url, authData)
      .then((response) => {
        localStorage.setItem("name", response.data.returnData.name);
        localStorage.setItem("storeName", response.data.returnData.storeName);
        localStorage.setItem("email", response.data.returnData.email);
        localStorage.setItem("userType", response.data.returnData.userType);

        dispatch(myAccountUpdateRequestSuccess(response.data));
      })
      .catch((err) => {
        dispatch(myAccountUpdateRequestFail(err.response));
      });
  };
};

export const makePasswordUpdateRequestStart = () => {
  return {
    type: actionTypes.PASSWORD_UPDATE_REQUEST_START,
  };
};

export const makePasswordUpdateRequestSuccess = (data) => {
  return {
    type: actionTypes.PASSWORD_UPDATE_REQUEST_SUCCESS,
    data: data,
  };
};

export const makePasswordUpdateRequestFail = (error) => {
  return {
    type: actionTypes.PASSWORD_UPDATE_REQUEST_FAIL,
    error: error,
  };
};

export const makePasswordUpdateRequest = (userId, jwt, data) => {
  return (dispatch) => {
    dispatch(makePasswordUpdateRequestStart());
    const authData = {
      userId: userId,
      jwt: jwt,
      data: data,
      action: "updatePassword",
    };
    let url = config.url.API_URL + "/apiEndPoint.php";
    axios
      .post(url, authData)
      .then((response) => {
        dispatch(makePasswordUpdateRequestSuccess(response.data));
      })
      .catch((err) => {
        dispatch(makePasswordUpdateRequestFail(err.response));
      });
  };
};

export const setNotificationMessageNull = () => {
  return {
    type: actionTypes.SET_AUTH_NOTIFY_MESSAGE_NULL,
  };
};

export const addToCart = (data) => {
  return {
    type: actionTypes.ADD_TO_CART,
    data: data,
  };
};

export const onUpdateUserProfile = (data) => {
  localStorage.setItem("name", data.name);
  localStorage.setItem("storeName", data.storeName);
  localStorage.setItem("email", data.email);
  return {
    type: actionTypes.UPDATE_USER_PROFILE,
    data: data,
  };
};
