import { format, isValid, parseISO } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export function insertItem(array, action) {
  let newArray = array.slice();
  newArray.splice(action.index, 0, action.item);
  return newArray;
}

// needs the index and the item attributes itself
export const updateObjectInArray = (array, action) => {
  return array.map((item, index) => {
    if (index !== action.index) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...action.item,
    };
  });
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
};

export const getLastItem = (thePath) =>
  thePath.substring(thePath.lastIndexOf("/") + 1);

export const formatDate = (date) => {
  const parsedDate = parseISO(date);
  if (isValid(parsedDate)) {
    return format(parsedDate, "LLL dd, YYY hh:mm a");
  }
  return date;
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export function convertCaseType(caseType) {
  var caseTypeString = "";
  switch (caseType) {
    case "0":
      caseTypeString = "Cases";
      break;

    case "1":
      caseTypeString = "KG";
      break;

    case "2":
      caseTypeString = "Pieces";
      break;

    case "3":
      caseTypeString = "Rolls";
      break;

    case "4":
      caseTypeString = "Bags";
      break;

    default:
      caseTypeString = "Error";
      break;
  }
  return caseTypeString;
}

// var newProduct = {
//   managerId: "",
//   productId: "",
//   items: {
//     0: 0,
//     1: 0,
//     2: 0,
//     3: 0,
//     4: 0,
//   },
// };

// valuesForPo.forEach((product) => {
//   newProduct = {
//     ...newProduct,
//     managerId: product.managerId,
//     productId: product.items[0].productId,
//   };

//   product.items.forEach((item) => {
//     const caseType = item.caseType;
//     const newAmount = newProduct.items[caseType] + +item.newAmount;
//     newProduct.items[caseType] = newAmount;
//   });
// });


export const convertToDefEventPara = (name, value) => {
  return {
    target: {
      name,
      value,
    },
  };
};