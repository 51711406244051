import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SettingsPasswordForm from "../../components/forms/SettingsPasswordForm";
import SettingsProfileForm from "../../components/forms/SettingsProfileForm";

import ErrorModal from "../../components/ModalTemplates/EmptyModal";
import SuccessNotification from "../../components/SuccessNotification";

import * as actions from "../../store/actions/index";

const Settings = (props) => {
  const {
    checkRequestError,
    email,
    name,
    onUpdateUserProfile,
    storeName,
    userId,
    userType,
  } = props;

  useEffect(() => {
    document.title = "Settings";
  }, []);

  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [show, setShow] = useState({ show: false });

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <SettingsProfileForm
            checkRequestError={checkRequestError}
            onUpdateUserProfile={onUpdateUserProfile}
            profile={{ email: email, name: name, storeName: storeName }}
            setErrorModalOpen={setErrorModalOpen}
            setShow={setShow}
            userId={userId}
            userType={userType}
          />
          <SettingsPasswordForm
            checkRequestError={checkRequestError}
            setErrorModalOpen={setErrorModalOpen}
            setShow={setShow}
            userId={userId}
          />
        </div>

        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />

        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    name: state.auth.name,
    storeName: state.auth.storeName,
    userId: state.auth.userId,
    userType: state.auth.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUserProfile: (data) => dispatch(actions.onUpdateUserProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
