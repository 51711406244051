import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import ErrorModal from "../../../components/ModalTemplates/EmptyModal";
import SidePanel from "../../../components/SidePanel";
import SuccessNotification from "../../../components/SuccessNotification";

import { makeApiCall } from "../../../shared/makeApiCall";

import { formatDate } from "../../../shared/utility";

import ManagerOrderItems from "../../../components/SidePanelComponents/ManagerOrderItems";

const MyOrders = (props) => {
  const { checkRequestError, userId } = props;

  useEffect(() => {
    document.title = "My Orders";

    fetchMyOrders();
  }, []);

  const [orders, setOrders] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const searchRef = useRef();

  var searchValue = null;

  const fetchMyOrders = async function () {
    const authData = {
      action: "fetchMyOrders",
      userId: userId,
      search: searchValue,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setOrders(callResult.data.orders);
      setLoading(false);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">{/* Search area */}</div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
            </div>
            <div className="-mx-4 mt-8 shadow-sm ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Order #
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Date created
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 sm:pr-6 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {orders.map((order, index) => (
                    <tr key={order.orderId}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {order.orderId}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {formatDate(order.addedDate)}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        {order.orderStatus}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => {
                            setSidePanelExtra({
                              childComponent: (
                                <ManagerOrderItems
                                  order={order}
                                  setOpen={setOpenSidePanel}
                                  setShow={setShow}
                                  setSidePanelExtra={setSidePanelExtra}
                                  subtitle={null}
                                  userId={userId}
                                />
                              ),
                              size: "5xl",
                            });
                            setOpenSidePanel(true);
                          }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(MyOrders);
