export default function routes(userType, pathname) {
  var title;
  var routes;
  if (userType === "1") {
    routes = [
      {
        name: "Create PO",
        href: "/create-po",

        current: false,
      },
      { name: "Products", href: "/products", current: false },
      {
        name: "Categories",
        href: "/categories",

        current: false,
      },
      {
        name: "Purchase Orders",
        href: "/purchase-orders",

        current: false,
      },
      {
        name: "Managers",
        href: "/managers",

        current: false,
      },
      {
        name: "Suppliers",
        href: "/suppliers",

        current: false,
      },
    ];
  } else {
    routes = [
      { name: "Create Order", href: "/create-order", current: false },
      {
        name: "My Orders",
        href: "/my-orders",

        current: false,
      },
    ];
  }

  routes.forEach((route) => {
    if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes,
    title: title,
  };

  return returnArray;
}
