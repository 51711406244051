import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import ErrorModal from "../../../components/ModalTemplates/EmptyModal";
import SidePanel from "../../../components/SidePanel";
import SuccessNotification from "../../../components/SuccessNotification";

import { makeApiCall } from "../../../shared/makeApiCall";

import { updateObjectInArray } from "../../../shared/utility";

import Input from "../../../components/controls/Input";
import Select from "react-select";

const caseTypes = [
  { value: 0, label: "Cases" },
  { value: 1, label: "Kg" },
  { value: 2, label: "Pieces" },
  { value: 3, label: "Rolls" },
  { value: 4, label: "Bags" },
];

const Shop = (props) => {
  const { checkRequestError, userId } = props;

  useEffect(() => {
    document.title = "Create new order";

    fetchAllProducts();
  }, []);

  const [categories, setCategories] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });
  const [notes, setNotes] = useState("");

  const searchRef = useRef();

  var searchValue = null;

  const fetchAllProducts = async function () {
    const authData = {
      action: "fetchAllProductsForShop",
      userId: userId,
      search: searchValue,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setCategories(callResult.data.categories);
      setLoading(false);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        setLoading(false);
      }
    }
  };

  const handleSearch = () => {
    searchValue = searchRef.current.value;

    if (searchValue.length >= 3) {
      fetchAllProducts();
    }
  };

  const handleInputChange = (fieldName, catIndex, prodIndex, userInput) => {
    const currentCategories = [...categories];
    const currentCategoryProducts = [...currentCategories[catIndex].products];

    var newProduct = {};
    if (fieldName == "orderAmount") {
      newProduct = {
        ...currentCategoryProducts[prodIndex],
        orderAmount: userInput,
      };
    } else if (fieldName == "caseType") {
      newProduct = {
        ...currentCategoryProducts[prodIndex],
        caseType: userInput,
      };
    }

    const payload = {
      index: prodIndex,
      item: newProduct,
    };

    const updatedCategoryProducts = updateObjectInArray(
      currentCategoryProducts,
      payload
    );

    const prodPayload = {
      index: catIndex,
      item: { products: updatedCategoryProducts },
    };

    const updatedProducts = updateObjectInArray(currentCategories, prodPayload);

    setCategories(updatedProducts);
  };

  const addRequestFunc = async function () {
    setDisableSubmitButton(true);

    var isError = false;
    categories.forEach((category) => {
      category.products.forEach((product) => {
        if (product.orderAmount > 0 && !(product.caseType >= 0)) {
          setErrorModalOpen({
            open: true,
            title: "Sorry!",
            subtitle: "Please add case type for: " + product.productName,
          });
          isError = true;
          setDisableSubmitButton(false);
        }
      });
    });

    const dataToSend = {
      categories: categories,
      notes: notes,
    };

    if (!isError) {
      const authData = {
        action: "addRequest",
        userId: userId,
        data: dataToSend,
      };
      let callResult = await makeApiCall(authData);
      setDisableSubmitButton(false);
      if (callResult.success) {
        setShow({ show: true, title: "Order request created." });
        window.location.href = "/my-orders/";
      } else {
        const isLogout = checkRequestError(callResult.errorStatus);
        if (!isLogout) {
          setErrorModalOpen({
            open: true,
            title: "Sorry!",
            subtitle: "We could not add the product.",
          });
        }
      }
    }
  };

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                {/* <input
                  type="search"
                  name="searchProduct"
                  id="searchProduct"
                  className="block w-1/3 mt-2 rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300"
                  placeholder="Search"
                  onChange={handleSearch}
                  ref={searchRef}
                /> */}
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto ml-2"
                  onClick={() => addRequestFunc()}
                  disabled={disableSubmitButton}
                >
                  {disableSubmitButton ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Submit order"
                  )}
                </button>
              </div>
            </div>
            {categories.map((category, catIndex) => (
              <div
                className="-mx-4 mt-8 shadow-sm ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg"
                key={category.categoryId}
              >
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 sm:pl-6 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        {category.categoryName}
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pr-4 pl-3 sm:pr-6 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {category.products.map((product, prodIndex) => (
                      <tr key={product.productId}>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                          {product.productName}
                        </td>
                        <td className="py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 flex">
                          <Input
                            labelOn={false}
                            name="orderAmount"
                            onChange={(e) =>
                              handleInputChange(
                                "orderAmount",
                                catIndex,
                                prodIndex,
                                e.target.value
                              )
                            }
                            value={product.orderAmount}
                            placeholder=""
                            explanation=""
                            type="number"
                            min="0"
                          />
                          <Select
                            name="caseType"
                            options={caseTypes}
                            onChange={(e) =>
                              handleInputChange(
                                "caseType",
                                catIndex,
                                prodIndex,
                                e.value
                              )
                            }
                            className="max-w-lg sm:max-w-xs ml-5"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            <div className="mt-1">
              <p>Notes: </p>
              <textarea
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg sm:max-w-xs"
                name="notes"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
                rows="4"
              />
            </div>
            <div>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto mt-5"
                onClick={() => addRequestFunc()}
                disabled={disableSubmitButton}
              >
                {disableSubmitButton ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Submit order"
                )}
              </button>
            </div>
          </div>
        </div>

        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Shop);
