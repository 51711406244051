import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import ErrorModal from "../../../components/ModalTemplates/EmptyModal";
import SidePanel from "../../../components/SidePanel";
import SuccessNotification from "../../../components/SuccessNotification";

import CreateSupplierForm from "../../../components/forms/CreateSupplierForm";

import { makeApiCall } from "../../../shared/makeApiCall";
import SimpleDeleteTemplate from "../../../components/ModalTemplates/SimpleDeleteTemplate";

const Suppliers = (props) => {
  const { checkRequestError, userId } = props;

  useEffect(() => {
    document.title = "Suppliers";

    fetchAllSuppliers();
  }, []);

  const [errorModalOpen, setErrorModalOpen] = useState({
    open: false,
    title: null,
    subtitle: null,
  });
  const [loading, setLoading] = useState(true);
  const [openDeleteModal, setOpenDeleteModel] = useState({
    actionMessage: "",
    actionFunc: null,
    itemId: 0,
    message: "",
    open: false,
    title: "",
  });
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [show, setShow] = useState({ show: false });
  const [sidePanelExtra, setSidePanelExtra] = useState({
    childComponent: null,
  });

  const searchRef = useRef();

  var pageNumber = 1;
  var searchValue = null;
  var totalPages = 0;

  var isLoading = true;

  const fetchAllSuppliers = async function () {
    if (pageNumber == 1) {
      setLoading(true);
      if (searchValue != null) {
        setSuppliers([]);
      }
    }

    isLoading = true;
    const authData = {
      action: "fetchAllSuppliers",
      userId: userId,
      search: searchValue,
      pageNumber: pageNumber,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      if (pageNumber > 1) {
        if (callResult.data.suppliers.length > 0) {
          setSuppliers((suppliers) => [
            ...suppliers,
            ...callResult.data.suppliers,
          ]);
        }
      } else {
        totalPages = callResult.data.total;
        setSuppliers(callResult.data.suppliers);
      }
      setLoading(false);
      isLoading = false;
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not fetch the data, please refresh the page.",
        });
        isLoading = false;
        setLoading(false);
      }
    }
  };

  const deleteSupplierFunc = async function (supplierId) {
    const authData = {
      action: "deleteSupplier",
      userId: userId,
      supplierId: supplierId,
    };
    let callResult = await makeApiCall(authData);

    if (callResult.success) {
      setOpenDeleteModel({ open: false });
      setShow({ show: true, title: "Supplier deleted." });
      const updatedSuppliers = suppliers.filter(
        (supplier) => supplier.supplierId !== supplierId
      );
      setSuppliers(updatedSuppliers);
    } else {
      const isLogout = checkRequestError(callResult.errorStatus);
      if (!isLogout) {
        setErrorModalOpen({
          open: true,
          title: "Sorry!",
          subtitle: "We could not delete the supplier, please try again.",
        });
      }
    }
  };

  const handleSearch = () => {
    searchValue = searchRef.current.value;

    pageNumber = 1;
    totalPages = 1;
    if (searchValue.length >= 3) {
      //   fetchAllSuppliers();
    }
  };

  return (
    <div>
      <main>
        <div className="mx-auto py-6 sm:px-6 lg:px-8">
          {/* Start here */}
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                {/* <h1 className="text-xl font-semibold text-gray-900">
                  Suppliers
                </h1> */}
                <input
                  type="search"
                  name="searchSupplier"
                  id="searchSupplier"
                  className="block w-1/3 mt-2 rounded-lg rounded-l-md pl-2 sm:text-md border border-gray-300"
                  placeholder="Search"
                  onChange={handleSearch}
                  ref={searchRef}
                />
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500"
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 sm:w-auto ml-2"
                  onClick={() => {
                    setSidePanelExtra({
                      childComponent: (
                        <CreateSupplierForm
                          checkRequestError={checkRequestError}
                          suppliers={suppliers}
                          supplier={null}
                          fetchAllSuppliers={fetchAllSuppliers}
                          isEdit={false}
                          setSuppliers={setSuppliers}
                          setOpen={setOpenSidePanel}
                          setShow={setShow}
                          setErrorModalOpen={setErrorModalOpen}
                          setSidePanelExtra={setSidePanelExtra}
                          subtitle="Fill in the information below to add a new supplier."
                          title="Add new supplier"
                          userId={userId}
                        />
                      ),
                    });
                    setOpenSidePanel(true);
                  }}
                >
                  Add supplier
                </button>
              </div>
            </div>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {suppliers.map((supplier) => (
                    <tr key={supplier.supplierId}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {supplier.supplierName}
                      </td>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                        {supplier.email}
                      </td>

                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => {
                            setSidePanelExtra({
                              childComponent: (
                                <CreateSupplierForm
                                  checkRequestError={checkRequestError}
                                  suppliers={suppliers}
                                  supplier={supplier}
                                  fetchAllSuppliers={fetchAllSuppliers}
                                  isEdit={true}
                                  setSuppliers={setSuppliers}
                                  setOpen={setOpenSidePanel}
                                  setShow={setShow}
                                  setErrorModalOpen={setErrorModalOpen}
                                  setSidePanelExtra={setSidePanelExtra}
                                  subtitle={null}
                                  title={"Editing " + supplier.supplierName}
                                  userId={userId}
                                />
                              ),
                            });
                            setOpenSidePanel(true);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="text-indigo-600 hover:text-indigo-900 ml-2"
                          onClick={() => {
                            setOpenDeleteModel({
                              actionMessage: "Delete",
                              actionFunc: deleteSupplierFunc,
                              itemId: supplier.supplierId,
                              message:
                                "Are you sure you want to delete  " +
                                supplier.supplierName +
                                "?",
                              open: true,
                              title: "Delete " + supplier.supplierName + "?",
                            });
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <SimpleDeleteTemplate
          open={openDeleteModal.open}
          setOpen={setOpenDeleteModel}
          {...openDeleteModal}
        />
        <ErrorModal
          open={errorModalOpen.open}
          title={errorModalOpen.title}
          setOpen={setErrorModalOpen}
          subtitle={errorModalOpen.subtitle}
        />
        <SidePanel
          open={openSidePanel}
          setOpen={setOpenSidePanel}
          childComponent={sidePanelExtra.childComponent}
          size={sidePanelExtra.size}
        />
        <SuccessNotification
          show={show.show}
          setShow={setShow}
          title={show.title}
        />
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps)(Suppliers);
