import React, { useEffect, useState } from "react";

import { useParams } from "react-router";

import { useForm } from "../../components/useForm";

import { makeApiCall } from "../../shared/makeApiCall";

const initialValues = {
  password: null,
  confirmPassword: null,
};

const PasswordReset = () => {
  const { confirmationId } = useParams();

  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const validateConfirmId = async function () {
    const authData = {
      action: "validateResetPasswordConfirmationId",
      confirmationId: confirmationId,
    };
    let callResult = await makeApiCall(authData);
    if (callResult.success) {
      setEmail(callResult.data.email);
    } else {
      setError(callResult.data.subtitle);
    }
  };

  useEffect(() => {
    document.title = "Admin tool - Reset Password";

    validateConfirmId();
  }, []);

  const sendResetPasswordRequest = async function () {
    setLoading(true);
    setError(null);
    const authData = {
      action: "resetPassword",
      confirmationId: confirmationId,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    let callResult = await makeApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setSuccessMessage(callResult.data.message);
    } else {
      setError(callResult.data.subtitle);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (values.password.length >= 8) {
        if (values.password === values.confirmPassword) {
          sendResetPasswordRequest();
        } else {
          setError("Please make sure the two passwords match.");
        }
      } else {
        setError(
          "Please make sure the password length is at least 8 characters."
        );
      }
    }
  };

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <a href="/" className="text-3xl font-bold text-gray-900">
              Admin tool
            </a>
          </div>
          <div className="sm:flex-no-wrap px-4 sm:px-12">
            <div className="my-auto flex items-center">
              <a href="/login" className="text-indigo-700 font-bold">
                Back to login
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="min-h-screen flex flex-col py-12 sm:px-6 lg:px-8">
          {successMessage ? (
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6">
                <p> {successMessage}</p>
                <button
                  type="submit"
                  className="animat w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  onClick={() => (window.location = "/login")}
                >
                  Return to login screen
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="text-center text-3xl font-extrabold text-gray-900">
                  Create new password
                </h2>
                <p className="mt-6 text-center text-gray-900">
                  Please create your new password below.
                </p>
              </div>

              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          value={email}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 bg-gray-200 focus:outline-none  sm:text-sm"
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        New password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm new password
                      </label>
                      <div className="mt-1">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center"></div>

                      <div className="text-sm">
                        <a
                          href="/login"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Back to login
                        </a>
                      </div>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="animat w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        disabled={loading}
                      >
                        {loading ? (
                          <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        ) : (
                          "Set new password"
                        )}
                      </button>
                      <div className="text-sm mt-5 text-center font-medium text-red-500">
                        {error ? error : null}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default PasswordReset;
